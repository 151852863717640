import React from 'react';
import {graphql, Link} from 'gatsby';
import {GatsbyImage} from "gatsby-plugin-image";
import styled from 'styled-components';
import file from '../assets/images/lavender.svg';
import Instagram from '../components/Instagram';
import ContactFormCom from '../components/ContactFormCom';
import SEO from '../components/SEO';
import photoIcon from '../../static/photo-camera.svg';
import Icon from '../components/Icon';

const StyledImg = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

const StyledAboutUsWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  align-items: center;
  max-width: 800px;
  margin: 100px 50px 0 50px;

  ${({theme}) => theme.mq.desktop} {
    margin: 120px auto;
  }
`;

const OfferWrapper = styled.div`
  filter: drop-shadow(5px 5px 5px #fff);
  margin: 50px;
`;

const HoverImage = styled.img`
  transition: opacity 0.3s ease;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 250px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  opacity: 1;

  ${({theme}) => theme.mq.tablet} {
    max-width: 420px;
  }
`;

const PhotoOuterWrapper = styled.div`
  align-items: center;
  justify-content: center;
  position: relative;
`;

const PhotoInnerWrapper = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0 3rem 6rem rgba(0, 0, 0, 0.1);

  ${({theme}) => theme.mq.tablet} {
    width: 350px;
    height: 350px;
  }
`;

const StyledWelcomeText = styled.div`
  text-align: center;
  margin: 10px 0;
  line-height: 2.2rem;
  font-size: ${({theme}) => theme.font.size.xs};

  ${({theme}) => theme.mq.desktop} {
    font-size: ${({theme}) => theme.font.size.s};
    margin: 0;
  }
`;

const StyledParagraph = styled.p`
  margin: 4rem 0;
`;

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  font-family: 'Dancing Script', cursive;
  line-height: 4rem;
  font-size: ${({theme}) => theme.font.size.s};

  ${({theme}) => theme.mq.desktop} {
    font-size: ${({theme}) => theme.font.size.m};
  }
`;

const StyledIcon = styled(Icon)`
  display: inline-block;
  padding: 0 4px;
  vertical-align: middle;

  svg {
    height: 25px;
    width: 25px;
  }
`;

const LinkToSearch = styled.div`
  padding-top: 40px;
`;

const Onas = ({data}) => (
    <>
        <SEO title="O nas"/>
        <StyledAboutUsWrapper>
            <OfferWrapper>
                <div>
                    <PhotoOuterWrapper>
                        <HoverImage src={file} alt=""/>
                        <PhotoInnerWrapper>
                            <StyledImg
                                image={data.about.image.asset.gatsbyImageData}
                                alt="Renata Weber"
                            />
                        </PhotoInnerWrapper>
                    </PhotoOuterWrapper>
                </div>
            </OfferWrapper>

            <StyledWelcomeText>
                <h2 className="highlight">Cześć :)</h2>
                <StyledParagraph>
                    Mam na imię Renia i tak jak Ty{' '}
                    <b>
                        uwielbiam „otulać się” i zachwycać niepowtarzalnością i zapachem
                        kwiatów.
                    </b>{' '}
                    Od dzieciństwa w moim życiu gościły rośliny uprawiane na przemian z
                    moją babcią. Przepiękne rabaty kwiatowe otaczające jej dom lub
                    piętrzące się stosy doniczek z kwiatami na parapetach i tarasie.
                </StyledParagraph>
                <StyledParagraph>
                    Idąc za głosem serca{' '}
                    <b>ukończyłam Uniwersytet Przyrodniczy w Poznaniu</b> na wydziale
                    Ogrodnictwa. W międzyczasie zaczęła się moja przygoda z florystyką. W
                    spełnieniu życiowych marzeń pomógł mi partner który doradzał i „był
                    obok”, kibicując przy stawianiu pierwszych zawodowych kroków. I tak
                    obecnie:
                </StyledParagraph>
                <StyledList>
                    <li>Florystka z zawodu, zamiłowania i pasji</li>
                    <li>Właścicielka Pracowni Florystycznej</li>
                    <li>Szczęśliwa żona ogrodnika</li>
                    <li>Spełniona mama dwójki dzieci</li>
                </StyledList>
                <StyledParagraph>
                    W naszym domu często królują kwiaty doniczkowe, rabatowe oraz bukiety.
                    Z radością widzę jak naszą pasję do roślin powoli przejmują dzieci
                    doceniając każdy dar natury. W ich oczach można zobaczyć zachwyt,
                    który Ja staram się przekazywać dalej w swoich pracach florystycznych.
                </StyledParagraph>
                <StyledParagraph>
                    <b>Uwielbiam łączyć naturę z kwiatami sezonowymi.</b> Dzięki temu
                    tworzą one przepiękne kompozycje które do nas przemawiają. W końcu{' '}
                    <b>każdy kwiat to coś więcej niż tylko dekoracja</b>. To także symbol
                    który budzi niepowtarzalne emocje i uczucia.
                </StyledParagraph>
                <StyledParagraph>
                    Zachęcam do odwiedzenia <Link to="/galeria">naszej galerii</Link> oraz
                    zapoznania się z <Link to="/oferta">ofertą</Link>.
                </StyledParagraph>
                <div>
                    Ściśle współpracujemy z <StyledIcon src={photoIcon}/>{' '}
                    <a href="https://www.instagram.com/ulubionerzeczy">ulubionerzeczy</a>.
                </div>
                <LinkToSearch>
                    <a href="https://www.kwiaciarnie-weselne.pl/" title="Kwiaciarnie-Weselne.pl - Prestiżowy Katalog Firm Weselnych"><img src="https://static.organizacja-wesel.pl/templates/default/img/banner/katalog/weselny.png" alt="Katalog firm weselnych poleca!"/></a>
                </LinkToSearch>
            </StyledWelcomeText>
        </StyledAboutUsWrapper>
        <ContactFormCom/>
        <Instagram/>
    </>
);

export default Onas;

export const query = graphql`
  query SinglePageAboutQuery {
    about: sanityAbout {
      image {
        asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
    }
  }
`;
